import { Component } from 'react'
import { css } from '@emotion/react'

import { CardElevation, ButtonDegrade, Oferta } from '@findep/mf-landings-core';

import { Typography, Container, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import { CataloguesService } from '../../services/LandingV4';

import { navigate } from "../../helpers/queryNavigate"
import formatNumber from '../../helpers/formatNumber'

import CarouselImg from '../img/CarouselImg.compilable'

const imageContainer = css`
    display: flex;
    height: 250px;
    position: center;
`
class NumRastreo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            errorMessage: null,
            errorService: false,
            mobileNumber: '',
            calculatedRfc: '',
            tdatos: !((typeof window !== 'undefined') && sessionStorage.getItem('tdatos') === null)
                ?
                (typeof window !== 'undefined')
                    ? JSON.parse(sessionStorage.getItem('tdatos'))
                    : ''
                :
                '',
            flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null)
                ?
                (typeof window !== 'undefined')
                    ? JSON.parse(sessionStorage.getItem('flujo'))
                    : ''
                :
                ''
        }

        const { flujo } = this.state

        this.state = {
            ...this.state,
            offerProps: [
                {
                    icono: ArrowForwardIcon,
                    titulo: 'Monto:',
                    texto: `${formatNumber(flujo?.preOferta?.oferta.amount || 0)}`
                },
                {
                    icono: ArrowForwardIcon,
                    titulo: 'Pago:',
                    texto: `${formatNumber(flujo?.preOferta?.oferta.payment || 0)}`
                },
            ]
        }
    }

    async componentDidMount() {
        const { creditApplicationId, datosAdicionales: { tenant } = {} } = this.state.flujo

        const catalogues = new CataloguesService();

        try {
            const { data: { mobileNumber, calculatedRfc } } = await catalogues.customer(tenant, creditApplicationId);

            this.setState({ mobileNumber, calculatedRfc })
        } catch (e) {
            let errorMessage

            if (e.response?.code === 404) {
                errorMessage = 'Id no encontrado!'
            }

            this.setState({ errorService: true, errorMessage })
        }
    }

    nextPage() {
        navigate(this.props.pageContext?.next?.path)
    }

    render() {
        const { pageContext } = this.props

        return (
            <Container align="center">
                <br />
                <Oferta datos={this.state.offerProps} colorOne="#FF9F01" colorTwo="#FA4616" />

                <Typography align="center" color='primary' font-size="3rem" fontWeight='fontWeightBold'>
                    <h2>Número de rastreo</h2>
                </Typography>

                <CardElevation>
                    <Typography align="left">
                        Estás a unos pasos de completar tu solicitud,
                        si no contarás con tiempo o con algún documento ¡No te preocupes!
                        Puedes retomar tu solicitud con tu numero de rastreo:
                    </Typography>

                    <Typography align="center" color='primary' font-size="4rem" fontWeight='fontWeightBold'>
                        <h2>No. {this.state.tdatos?.telefono || this.state.mobileNumber || this.state.calculatedRfc}</h2>
                    </Typography>
                </CardElevation>

                <br />

                <ButtonDegrade
                    icon={ArrowForwardIcon}
                    id="numero-rastreo-obtener-prestamo-button"
                    onClick={() => { this.nextPage() }}
                    textButton="OBTENER MI CRÉDITO"
                />

                <br />
                <br />

                <CardElevation>
                    <CarouselImg customCss={imageContainer} filename="3.svg" company={pageContext.company} />
                </CardElevation>

                <br />
                
                <Snackbar
                    autoHideDuration={6000}
                    onClose={() => this.setState({ errorService: false, errorMessage: null })}
                    open={this.state.errorService}
                >
                    <Alert
                        onClose={() => this.setState({ errorService: false, errorMessage: null })}
                        severity="warning"
                        variant="filled"
                    >
                        {
                            this.state.errorMessage || 'Lo sentimos, por el momento el servicio no se encuentra disponible'
                        }
                    </Alert>
                </Snackbar>
            </Container>
        )
    }
}

export default NumRastreo