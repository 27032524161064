import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const CarouselImg = ({ company, filename, customCss, width, height }) => {
  const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "carousel"}}) {
          edges {
            node {
              id
              imagenes {
                company
                alt
                description
                src {
                  base
                  publicURL
                  childImageSharp {
                    fixed(height: 300, quality: 85) {
                      base64
                      width
                      height
                      src
                      srcSet
                    }
                  }
                }
              }
            }
          }
        }
      }      
  `)
  // console.log(company, filename)
  const image = data.allComponentsJson.edges[0].node.imagenes.find(item => item.company === company && item.src.base === filename)
  
  // console.log(image)
  return image && (image.src.childImageSharp ? <Img css={customCss} fixed={image.src.childImageSharp.fixed} alt={image.alt} /> : <img css={customCss} src={image.src.publicURL} alt={image.alt} width={width} height={height} />)
}

export default CarouselImg