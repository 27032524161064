const integerFormat = (num, separation, separator) => {
    if (num.length > separation) {
      const tmp = num.split('')
      const first = num.length % separation
      const blocks = parseInt(num.length / separation)
      let sections = [tmp.slice(0, first).join('')]
      for (let i = 0; i < blocks; i++) {
        sections = [...sections, tmp.slice(i * separation + first, (i + 1) * separation + first).join('')]
      }
      return sections.filter((segmento) => segmento !== '').join(separator)
    } else return num
  }
  
  const formatNumber = (num, separation = 3, separator = ',', simbol = '$', decimals = 2, decimalSeparator = '.') => {
    const number = Number(num)
      .toFixed(decimals)
      .split('.')
    return `${simbol} ${integerFormat(number[0].replace(/\s/g, ''), separation, separator)}${decimalSeparator}${
      number[1] ? number[1] : ''
    }`
  }
  export default formatNumber
  